import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router, Route, BrowserRouter, Switch, IndexRoute} from 'react-router-dom';
/*Modules*/
import Home from "./Home";
import Experience from "./Experience";
import Lab from "./Lab";
import Contacts from "./Contacts";
/*Modules*/

class AppRouter extends Component {
    
    constructor(props) {
        super(props);
    }
    
    render() {
        return(
            <Switch>
                <Route exact path="/" render={(props) => <Home {...props} />}/>
                <Route exact path="/experience" render={(props) => <Experience {...props} />}/>
                {/*<Route exact path="/lab" render={(props) => <Lab {...props} />}/>*/}
                <Route exact path="/contacts" render={(props) => <Contacts {...props} />}/>
            </Switch>
        );
    }
    
}

export default AppRouter;