import React from 'react';
import '../css/Home.css';

class Contacts extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return(
            <div className="container-fluid">
                <div id="texto">
                    <a href="https://www.linkedin.com/in/hugoalexandremf/" target="_blank" rel="noopener noreferrer">
                        <img id="linkedin" src="./images/linkedin.png" alt="linkedin" height="40" width="40"/>
                    </a>
                    <div style={{fontSize: "21px", marginTop: "5px"}}>
                        <a href="https://www.linkedin.com/in/hugoalexandremf/" target="_blank" rel="noopener noreferrer">
                            Connect with me on LinkedIn
                        </a>
                    </div>
                </div>
            </div>
        );
    }

};

export default Contacts;
