import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import '../css/Navbar.css';
import {BrowserRouter as Router, Route, Link} from 'react-router-dom';

class Experience extends React.Component {
    
    constructor(props) {
        super(props);
    }
    
    render() {
        return(
            <div>
                <div className="topnav">
                    <Link to="/contacts"><b>Contacts</b></Link>
                    {/*<Link to="/lab"><b>Hondt</b></Link>*/}
                    <Link to="/experience"><b>Experience</b></Link>
                    <Link to="/"><b>Home</b></Link>
                </div>
                <img id="myImg" src="images/my.jpg" alt="Smiley face" height="50" width="50"/>
                <div className="logo"><b>@hugoalexandremf<span></span><span></span></b></div>
            </div>
        );
    }
    
};

export default Experience;
