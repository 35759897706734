import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

/*NotificationsUtils*/

toast.configure({
    autoClose: 8000,
    draggable: false,
    position: "top-center",
    closeOnClick: true
});

export function notifyUser(message, type = undefined, autoClose, reload = false, position = "top-center") {
    /*console.log("Utils - notifyUser");
     console.log(message);*/
    var options = {
        position: position,
        autoClose: autoClose,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        transtion: "slide",
        className: "notifyToUserBox",
        onClose: reload ? () => {
            window.location.reload();
        } : null
    };
    switch (type) {
        case "success":
            toast.success(message, options);
            break;
        case "info":
            toast.info(message, options);
            break;
        case "warning":
            toast.warning(message, options);
            break;
        case "error":
            toast.error(message, options);
            break;
        default:
            toast.info(message, options);
            break;
}

}
/*NotificationsUtils*/