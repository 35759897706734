import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import '../css/Lab.css';
import { Bar } from 'react-chartjs-2';
import {
    notifyUser
} from "./Utils";

const options = {
    indexAxis: 'y',
    // Elements options apply to all of the options unless overridden in a dataset
    // In this case, we are setting the border of each horizontal bar to be 2px wide
    elements: {
        bar: {
            borderWidth: 2
        }
    },
    responsive: true,
    plugins: {
        legend: {
            position: 'right'
        },
        title: {
            display: true,
            text: "Election results"
        }
    }
};

class Lab extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.hondtMethod = this.hondtMethod.bind(this);
        this.onChangePartiesVotesInputs = this.onChangePartiesVotesInputs.bind(this);
        
        this.state={
            cduVotes: 0,
            psdVotes: 0,
            psVotes: 0,
            panVotes: 0,
            beVotes: 0,
            chegaVotes: 0,
            iniciativaliberalVotes: 0,
            mapPartiesIntoVotes: {
                "cdu": {
                    "votes": 0,
                    "iterator": 2,
                    "vereadores": []
                },
                "psd": {
                    "votes": 0,
                    "iterator": 2,
                    "vereadores": []
                },
                "ps": {
                    "votes": 0,
                    "iterator": 2,
                    "vereadores": []
                },
                "pan": {
                    "votes": 0,
                    "iterator": 2,
                    "vereadores": []
                },            
                "be": {
                    "votes": 0,
                    "iterator": 2,
                    "vereadores": []
                },
                "chega": {
                    "votes": 0,
                    "iterator": 2,
                    "vereadores": []
                },
                "iniciativaliberal": {
                    "votes": 0,
                    "iterator": 2,
                    "vereadores": []
                }
            }
        };
    }
    
    componentDidMount() {
    }
    
    hondtMethod() {     
        let partiesVotes = {
            "cdu": this.state.cduVotes,
            "psd": this.state.psdVotes,
            "ps": this.state.psVotes,
            "pan": this.state.panVotes,
            "be": this.state.beVotes,
            "chega": this.state.chegaVotes,
            "iniciativaliberal": this.state.iniciativaliberalVotes
        };
        
        let mapPartiesIntoVotes = {
            "cdu": {
                "votes": this.state.cduVotes,
                "iterator": 2,
                "vereadores": []
            },
            "psd": {
                "votes": this.state.psdVotes,
                "iterator": 2,
                "vereadores": []
            },
            "ps": {
                "votes": this.state.psVotes,
                "iterator": 2,
                "vereadores": []
            },
            "pan": {
                "votes": this.state.panVotes,
                "iterator": 2,
                "vereadores": []
            },            
            "be": {
                "votes": this.state.beVotes,
                "iterator": 2,
                "vereadores": []
            },
            "chega": {
                "votes": this.state.chegaVotes,
                "iterator": 2,
                "vereadores": []
            },
            "iniciativaliberal": {
                "votes": this.state.iniciativaliberalVotes,
                "iterator": 2,
                "vereadores": []
            }
        };
        
        try {
            let best11List=[];
            /*console.log("mapPartiesIntoVotes: ", mapPartiesIntoVotes);
            console.log("best11List - length: ", best11List.length);*/

            let iterator=0;
            while(best11List.length<11) {            
                /*console.log("iteration #", iterator);
                console.log(mapPartiesIntoVotes);*/
                let majorPartyFromIteration="",
                    majorVotesFromIteration=0;

                for(var key of Object.keys(mapPartiesIntoVotes)) {
                    if(mapPartiesIntoVotes[key].votes>majorVotesFromIteration) {
                        majorPartyFromIteration=key;
                        majorVotesFromIteration=mapPartiesIntoVotes[key].votes;
                    }                
                }

                //console.log("majorParty: ", majorPartyFromIteration, " - majorVotes: ", majorVotesFromIteration);


                best11List.push(majorVotesFromIteration);
                //console.log("best11List: ", best11List.length);

                //var newMapPartiesIntoVotes = Object.assign({}, mapPartiesIntoVotes);
                let mapPartiesIntoVotes_copy = JSON.parse(JSON.stringify(mapPartiesIntoVotes));
                mapPartiesIntoVotes_copy[majorPartyFromIteration].vereadores.push(majorVotesFromIteration);
                mapPartiesIntoVotes_copy[majorPartyFromIteration].votes=((partiesVotes[majorPartyFromIteration])/(mapPartiesIntoVotes[majorPartyFromIteration].iterator));
                mapPartiesIntoVotes_copy[majorPartyFromIteration].iterator++;
                mapPartiesIntoVotes=mapPartiesIntoVotes_copy;
                iterator++;
            }

            /*console.log("\n\n----------------------------------");
            console.log(mapPartiesIntoVotes);*/
            
            /*let horizontalBarData=this.state.horizontalBarData;
            (horizontalBarData.datasets[0].data)[0]=mapPartiesIntoVotes["cdu"].vereadores.length;
            (horizontalBarData.datasets[0].data)[1]=mapPartiesIntoVotes["psd"].vereadores.length;
            (horizontalBarData.datasets[0].data)[2]=mapPartiesIntoVotes["ps"].vereadores.length;
            (horizontalBarData.datasets[0].data)[3]=mapPartiesIntoVotes["pan"].vereadores.length;
            (horizontalBarData.datasets[0].data)[4]=mapPartiesIntoVotes["be"].vereadores.length;
            (horizontalBarData.datasets[0].data)[5]=mapPartiesIntoVotes["chega"].vereadores.length;
            (horizontalBarData.datasets[0].data)[6]=mapPartiesIntoVotes["iniciativaliberal"].vereadores.length;
            this.setState({
                horizontalBarData: horizontalBarData
            });*/
            
            this.setState({
               mapPartiesIntoVotes: mapPartiesIntoVotes 
            });
        } catch(error) {
            notifyUser("Por favor verifique os valores inseridos nos campos.", "error", 7500, false, "top-right");
        }        
    }
    
    hondtMethodAlternative() {
        
    }
    
    onChangePartiesVotesInputs(event) {
        let eventTargetID=event.target.id,
            eventTargetValue=event.target.value;
        if((eventTargetValue==="") || ((/^\d+$/).test(eventTargetValue))) {
            this.setState({
                [eventTargetID+"Votes"]: isNaN(parseFloat(eventTargetValue)) ? 0 : parseInt(eventTargetValue)
            });
        }
    }
    
    render() {
        var horizontalBarData={
            labels: ["CDU", "PSD", "PS", "PAN", "BE", "Chega", "Iniciativa Liberal"],
            datasets: [
                {
                    label: '# of Vereadores',
                    data: [
                        this.state.mapPartiesIntoVotes["cdu"].vereadores.length,
                        this.state.mapPartiesIntoVotes["psd"].vereadores.length,
                        this.state.mapPartiesIntoVotes["ps"].vereadores.length,
                        this.state.mapPartiesIntoVotes["pan"].vereadores.length,
                        this.state.mapPartiesIntoVotes["be"].vereadores.length,
                        this.state.mapPartiesIntoVotes["chega"].vereadores.length,
                        this.state.mapPartiesIntoVotes["iniciativaliberal"].vereadores.length
                    ],
                    backgroundColor: [
                        "#809fff",
                        "rgba(255, 159, 64, 0.2)",
                        "rgba(255, 99, 132, 0.2)",
                        'rgba(75, 192, 192, 0.2)',
                        '#ff8c66',
                        '#ff8c66',
                        "#80dfff"
                    ],
                    borderColor: [
                        '#0039e6',
                        "rgba(255, 159, 64, 1)",
                        "rgba(255, 99, 132, 1)",
                        'rgba(75, 192, 192, 1)',
                        '#e63900',
                        '#ff8c66',
                        '#1ac6ff'
                    ],
                    borderWidth: 1
                }
            ]
        };
        return(
            <div className="container" style={{marginTop: "2%", padding: "2%", backgroundColor: "#ffffff"}}>
                <h1 style={{"color": "#000000", "textAlign": "center"}}>Hondt Method Calculator</h1>
                <h4 style={{"color": "#000000", "textAlign": "center"}}>Please insert the votes of each party.</h4>
                <form className="row g-3">
                    <div className="col-md-2">
                        <label for="inputEmail4" className="form-label">CDU</label>
                        <input type="text" id="cdu" className="form-control" placeholder="CDU" onChange={this.onChangePartiesVotesInputs} value={this.state.cduVotes}/>
                    </div>                    
                    <div className="col-md-2">
                        <label for="inputEmail4" className="form-label">PSD</label>
                        <input type="text" id="psd" className="form-control" placeholder="PSD" onChange={this.onChangePartiesVotesInputs} value={this.state.psdVotes}/>
                    </div>
                    <div className="col-md-2">  
                        <label for="inputEmail4" className="form-label">PS</label>
                        <input type="text" id="ps" className="form-control" placeholder="PS" onChange={this.onChangePartiesVotesInputs} value={this.state.psVotes}/>
                    </div>
                    <div className="col-md-2">
                        <label for="inputEmail4" className="form-label">PAN</label>
                        <input type="text" id="pan" className="form-control" placeholder="PAN" onChange={this.onChangePartiesVotesInputs} value={this.state.panVotes}/>
                    </div>
                    <div className="col-md-2">
                        <label for="inputEmail4" className="form-label">BE</label>
                        <input type="text" id="be" className="form-control" placeholder="BE" onChange={this.onChangePartiesVotesInputs} value={this.state.beVotes}/>
                    </div>
                    <div className="col-md-2">
                        <label for="inputEmail4" className="form-label">Chega</label>
                        <input type="text" id="chega" className="form-control" placeholder="Chega" onChange={this.onChangePartiesVotesInputs} value={this.state.chegaVotes}/>
                    </div>
                    <div className="col-md-2">
                        <label for="inputEmail4" className="form-label">Iniciativa Liberal</label>
                        <input type="text" id="iniciativaliberal" className="form-control" placeholder="Iniciativa Liberal" onChange={this.onChangePartiesVotesInputs} value={this.state.iniciativaliberalVotes}/>
                    </div>
                    <div className="col-12">
                      <button type="button" className="btn btn-primary" onClick={this.hondtMethod}>Calculate</button>
                    </div>
                </form>
                
                <div style={{textAlign: "center"}}>
                    <table id="resultsPerPartyTable" style={{border: "1px solid black"}}>
                        <thead>
                            <th>CDU</th>
                            <th>PSD</th>
                            <th>PS</th>
                            <th>PAN</th>
                            <th>BE</th>
                            <th>Chega</th>
                            <th>Iniciativa Liberal</th>
                        </thead>
                        <tbody>
                            <td>{this.state.mapPartiesIntoVotes.cdu.vereadores.length}</td>
                            <td>{this.state.mapPartiesIntoVotes.psd.vereadores.length}</td>
                            <td>{this.state.mapPartiesIntoVotes.ps.vereadores.length}</td>
                            <td>{this.state.mapPartiesIntoVotes.pan.vereadores.length}</td>
                            <td>{this.state.mapPartiesIntoVotes.be.vereadores.length}</td>
                            <td>{this.state.mapPartiesIntoVotes.chega.vereadores.length}</td>
                            <td>{this.state.mapPartiesIntoVotes.iniciativaliberal.vereadores.length}</td>
                        </tbody>
                    </table>
                </div>
                
                
                <Bar data={horizontalBarData} options={options} />
            </div>
        );
    }
    
};

export default Lab;
