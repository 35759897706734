import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import './css/App.css';
import Navbar from "./hugoalexandremf/Navbar";
import AppRouter from "./hugoalexandremf/AppRouter";

class App extends React.Component {
    
    constructor(props) {
        super(props);
    }
    
    render() {
        return(
            <div>
                <Navbar/>
                <AppRouter/>
            </div>
        );
    }
    
};

export default App;
