import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import '../css/Experience.css';

/**/
import python from "./images/python.png";

class Experience extends React.Component {
    
    constructor(props) {
        super(props);
    }
    
    render() {
        return(
            <div className="container-fluid">
		<h1 style={{"color": "white", "textAlign": "center"}}>Experience</h1>
		<div id="divExperienceTable">
                    <table id="experienceTable">
                        <tr>
                        <th><img id="imgPython" src="./images/python.png" alt="python" height="100" width="330"/></th>
                            <th><img id="imgJava" src="images/java.png" alt="java" height="200" width="110"/></th>					
                            <th><img id="imgJavascript" src="images/javascript.png" alt="javascript" height="250" width="270"/></th>
                        </tr>
                        <tr>					
                            <th><img id="imgSpring" src="images/spring.jpg" alt="spring" height="200" width="310"/></th>
                            <th><img id="imgSpringSecurity" src="images/spring_security.png" alt="spring_security" height="190" width="320"/></th>
                            <th><img id="imgSpringDataJPA" src="images/springDataJPA.png" alt="springDataJPA" height="190" width="320"/></th>	
                        </tr>
                        <tr>					
                            <th><img id="imgReact" src="images/react.png" alt="react" height="130" width="330"/></th>
                            <th><img id="imgAngular" src="images/angular.png" alt="angular" height="250" width="270"/></th>
                            <th><img id="imgHibernate" src="images/hibernate.svg" alt="hibernate" height="200" width="300"/></th>
                        </tr>
                        <tr>
                            <th><img id="imgMySQL" src="images/mysql.png" alt="mysql" height="180" width="290"/></th>
                            <th><img id="imgPostgreSQL" src="images/postgresql.jpg" alt="postgresql" height="170" width="330"/></th>
                            <th><img id="imgMongoDB" src="images/mongodb.png" alt="mongodb" height="100" width="340"/></th>
                        </tr>
                        <tr>			
                            <th><img id="imgYubico1" src="images/yubico_1.png" alt="yubico1" height="100" width="330"/></th>
                            <th><img id="imgOAuth2" src="images/oauth2.png" alt="oauth2" height="200" width="210"/></th>
                            <th><img id="imgWebAuthn3" src="images/webauthn_3.png" alt="webauthn3" height="100" width="330"/></th>
                        </tr>
                        <tr>			
                            <th><img id="imgSAML" src="images/saml.jpg" alt="saml" height="110" width="330"/></th>
                            <th><img id="imgWebAuthnFIDO2" src="images/webauthn_fido2.png" alt="webauthnFIDO2" height="180" width="330"/></th>
                            <th><img id="imgFIDO4" src="images/fido_4.png" alt="fido4" height="280" width="335"/></th>
                        </tr>
                        <tr>
                            <th><img id="imgGit" src="images/git.jpg" alt="git" height="240" width="250"/></th>	
                            <th><img id="imgSphinx" src="images/sphinx.png" alt="sphinx" height="280" width="280"/></th>
                            <th><img id="imgSphinx2" src="images/sphinx2.png" alt="sphinx2" height="100" width="270"/></th>
                        </tr>
                        <tr>
                            <th><img id="imgLinux" src="images/linux.jpg" alt="linux" height="190" width="190"/></th>
                            <th><img id="imgJSON" src="images/json.png" alt="json" height="190" width="220"/></th>
                            <th><img id="imgDjango" src="images/django.png" alt="django" height="210" width="280"/></th>
                        </tr>
                        <tr>
                            <th><img id="imgR" src="images/r.png" alt="r" height="200" width="300"/></th>
                            <th><img id="imgNeo4J" src="images/neo4j.jpg" alt="neo4j" height="130" width="330"/></th>
                            <th><img id="imgHTMLCSS" src="images/html_css.png" alt="html_css" height="150" width="270"/></th>
                        </tr>
                        <tr>
                            <th><img id="imgC" src="images/c.png" alt="c" height="190" width="190"/></th>
                            <th><img id="imgPentaho" src="images/pentaho.png" alt="pentaho" height="190" width="330"/></th>
                            <th><img id="imgHaskell" src="images/haskell.png" alt="haskell" height="210" width="255"/></th>
                        </tr>
                    </table>
		</div>            
            </div>
        );
    }
    
};

export default Experience;
