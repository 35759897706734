import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import '../css/Home.css';

class Home extends React.Component {
    
    constructor(props) {
        super(props);
    }
    
    render() {
        return(
            <div className="container">                
                <div id="texto">
                        Currently working as Full Stack Software Engineer at Kuehne+Nagel since August 2024.
                        <br/>
                        <br/>
                        Full Stack Software Engineer at DigitalSign - Certificadora Digital S.A. (September 2017 - August 2024).
                        <br/>
                        <br/>
                        Software Engineer & Machine Learning Developer on an investigation project at Universidade do Minho, that implemented an Automatic Speech Recognition (ASR) System on a medical institution (February 2017-September 2017).
                        <br/>
                        <br/>
                        Computer Science Graduated at Universidade do Minho.
                        <br/>
                        Master's Degree in Business Intelligence and Applications Engineering - (additional profile in Software Systems Engineering and thesis in Business Intelligence - "Satisfaction levels establishment through opinion and sentiment mining") at Universidade do Minho.   
                </div>
            </div>
        );
    }
    
};

export default Home;
